import { usePlayer } from '~/components/player/PlayerContext';

export default function VolumeControl() {
  const player = usePlayer();

  return (
    <input
      type="range"
      value={player.volume}
      max={1}
      step="any"
      onInput={event => {
        window.jhplayer.volume = event.target.value;
      }}
    />
  );
}
