import { gql } from '@apollo/client/core';
import { A, cache, createAsync } from '@solidjs/router';
import { For } from 'solid-js';

import styles from '~/components/TopTags.module.scss';
import { client } from '~/utils/graphql';

const TOP_TAGS = gql`
  query TopTags {
    tags(options: { limit: 20, sort: [{ usageCount: DESC }] }) {
      title
      slug
      usageCount
    }
  }
`;

const getTags = cache(async () => {
  'use server';

  const result = await client.query({ query: TOP_TAGS });

  return result.data.tags;
}, 'tags');

export default function TopTags() {
  const tags = createAsync(() => getTags());

  return (
    <ul class={styles['tag-list']}>
      <For each={tags()}>
        {tag => (
          <li>
            <A href={`/tag/${tag.slug as string}`}>
              <span class="hashtag">#</span>
              {tag.title}
            </A>
          </li>
        )}
      </For>
      <li>
        <A href="/posts">Todos os Artigos</A>
      </li>
    </ul>
  );
}
