import { usePlayer } from '~/components/player/PlayerContext';

import styles from '~/components/player/PlayButton.module.scss';

const states = {
  play: <IconTablerPlayerPauseFilled />,
  pause: <IconTablerPlayerPlayFilled />,
  buffering: (
    <span class={styles.spin}>
      <IconTablerLoader2 />
    </span>
  ),
};

const labels = {
  play: 'Pause',
  pause: 'Play',
  buffering: 'Carregando...',
};

export default function PlayButton() {
  const player = usePlayer();

  return (
    <button
      class={styles.button}
      aria-label={labels[player.state]}
      onClick={() => {
        void window.jhplayer.toggle();
      }}
    >
      {states[player.state]}
    </button>
  );
}
