import { Show } from 'solid-js';
import { isServer } from 'solid-js/web';

import PlayButton from '~/components/player/PlayButton';
import PlayerContext from '~/components/player/PlayerContext';
import Progress from '~/components/player/Progress';
import RadioShowMetadata from '~/components/player/RadioShowMetadata';
import SongMetadata from '~/components/player/SongMetadata';
import SourceSelect from '~/components/player/SourceSelect';
import VolumeControl from '~/components/player/VolumeControl';

export default function Player() {
  return (
    <Show when={!isServer}>
      <PlayerContext>
        <div style={{ 'text-align': 'center' }}>
          <PlayButton />
          <VolumeControl />
          <SourceSelect />
          <RadioShowMetadata />
          <SongMetadata />
          <Progress />
        </div>
      </PlayerContext>
    </Show>
  );
}
