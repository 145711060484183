export function formatDuration(seconds: number, noZeroFill = true) {
  const isNegative = seconds < 0;
  seconds = Math.floor(Math.abs(seconds));
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  const sec = (seconds % 60).toString().padStart(2, '0');
  const min =
    !noZeroFill || hours
      ? (minutes % 60).toString().padStart(2, '0')
      : (minutes % 60).toString();

  let returnValue = `${min}:${sec}`;

  if (hours) {
    const hr = noZeroFill
      ? hours.toString()
      : hours.toString().padStart(2, '0');
    returnValue = `${hr}:${returnValue}`;
  }

  if (isNegative) {
    returnValue = `\u2212${returnValue}`;
  }

  return returnValue;
}
