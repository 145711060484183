import { createSignal } from 'solid-js';
import { usePlayer } from '~/components/player/PlayerContext';
import { formatDuration } from '~/utils/formatDuration';

export default function Progress() {
  const player = usePlayer();
  const currentSongDuration = () =>
    player.metadata?.song_history[0].duration ?? -1;
  const [isReversedProgress, setIsReversedProgress] = createSignal(false);

  return (
    <p style={{ 'font-variant-numeric': 'tabular-nums' }}>
      <span
        onClick={() => {
          setIsReversedProgress(x => !x);
        }}
      >
        {formatDuration(
          isReversedProgress() && currentSongDuration() > -1
            ? player.progress - currentSongDuration()
            : player.progress,
        )}
      </span>{' '}
      <progress
        {...{
          value: currentSongDuration() > -1 ? player.progress : undefined,
          max: currentSongDuration() > -1 ? currentSongDuration() : undefined,
        }}
      />{' '}
      {currentSongDuration() > -1 ? formatDuration(currentSongDuration()) : ''}
    </p>
  );
}
