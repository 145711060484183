import {
  createContext,
  onMount,
  Show,
  useContext,
  type ParentProps,
} from 'solid-js';
import { createStore } from 'solid-js/store';

import { isNativeApp } from '~/utils/isNativeApp';

interface Store {
  state: 'play' | 'pause' | 'buffering';
  volume: number;
  metadata?: Record<string, any> | null;
  progress: number;
  allSources: string[];
  currentSourceIndex: number;
}

const INITIAL_VOLUME = 0.8;

const [store, setStore] = createStore<Store>({
  state: 'pause',
  volume: INITIAL_VOLUME,
  metadata: null,
  progress: -1,
  allSources: [],
  currentSourceIndex: -1,
});

const Context = createContext(store);

function onPlayerLoad(callback: () => void) {
  if (isNativeApp()) {
    return;
  }

  if (window.jhplayer) {
    callback();
    return;
  }

  document.addEventListener('jhplayerinit', callback, { once: true });
}

export function usePlayer() {
  return useContext(Context);
}

export default function PlayerContext(props: ParentProps) {
  onMount(() => {
    onPlayerLoad(() => {
      window.jhplayer.volume = INITIAL_VOLUME;

      window.jhplayer.on({
        'play pause buffering'(event: any) {
          setStore('state', event.type);
        },
        volumechange() {
          setStore('volume', window.jhplayer.volume);
        },
        gotmetadata(event: any) {
          setStore('metadata', event.detail);
        },
        metadataerror() {
          setStore('metadata', null);
        },
        songprogress(event: any) {
          setStore('progress', event.detail);
        },
      });

      setStore('allSources', window.jhplayer.allSources);
      setStore('currentSourceIndex', window.jhplayer.currentSourceIndex);

      try {
        setStore('metadata', window.jhplayer.metadata);
      } catch {
        //
      }
    });
  });

  return (
    <Context.Provider value={store}>
      <Show when={store.metadata} fallback="Carregando player...">
        {props.children}
      </Show>
    </Context.Provider>
  );
}
