import Image from '~/components/player/Image';
import { Show } from 'solid-js';
import { usePlayer } from '~/components/player/PlayerContext';
import styles from '~/components/player/SongMetadata.module.scss';

export default function SongMetadata() {
  const player = usePlayer();
  const currentSong = () => player.metadata?.song_history[0] ?? {};

  return (
    <div
      style={{
        display: 'flex',
        gap: 'var(--size-4)',
        'justify-content': 'center',
        'align-items': 'center',
      }}
    >
      <Image
        displayFallback
        class={styles.cover}
        sources={currentSong().cover ?? []}
        alt=""
      />
      <div style={{ 'text-align': 'start' }}>
        <Show when={currentSong().title}>
          <div>
            <b>{currentSong().title}</b>
          </div>
        </Show>
        <Show when={currentSong().artist}>
          <div>{currentSong().artist}</div>
        </Show>
        <Show when={currentSong().album}>
          <div>
            <cite>{currentSong().album}</cite>
          </div>
        </Show>
      </div>
    </div>
  );
}
