import { createRenderEffect, createSignal, For } from 'solid-js';

import Image from '~/components/player/Image';
import { usePlayer } from '~/components/player/PlayerContext';
import styles from '~/components/player/RadioShowMetadata.module.scss';

const listFormat = new Intl.ListFormat(import.meta.env.VITE_LOCALE, {
  type: 'conjunction',
});

export default function RadioShowMetadata() {
  const player = usePlayer();

  const radioShow = () => player.metadata?.program ?? {};

  const djNames = () => {
    const djs = radioShow().djs ?? [];

    if (djs.length > 0) {
      return (
        (djs.length === 1 ? 'DJ ' : 'DJs ') +
        listFormat.format(djs.map((dj: any) => dj.name))
      );
    }

    if (radioShow().type === 'playlist') {
      return 'Playlist';
    }

    if (radioShow().type === 'commercial_break') {
      return 'Intervalo Comercial';
    }

    return '';
  };

  const genre = () => {
    return radioShow().type === 'commercial_break'
      ? ''
      : (radioShow().genre as string);
  };

  const djAvatars = () => {
    const djs = radioShow().djs ?? [];

    if (djs.length > 0) {
      return djs.map((dj: any) => dj.avatar);
    }

    return [[{ sizes: 'any', src: '/assets/playlist.jpg' }]];
  };

  const [avatarsRef, setAvatarsRef] = createSignal<HTMLDivElement>();
  createRenderEffect(() => {
    const avatars = avatarsRef();
    if (!avatars) {
      return;
    }

    avatars.style.width = getComputedStyle(avatars).width;
  });

  return (
    <div
      style={{
        display: 'flex',
        gap: 'var(--size-4)',
        'justify-content': 'center',
        'inline-size': 'fit-content',
        'margin-inline': 'auto',
      }}
    >
      <Image
        class={styles.cover}
        sources={radioShow().cover ?? []}
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        alt={`Capa do programa ${radioShow().name}`}
      />
      <div style={{ 'text-align': 'start' }}>
        <h2 style={{ 'margin-block': '0' }}>
          <cite>{radioShow().name}</cite>
        </h2>
        <div
          style={{
            display: 'flex',
            'align-items': 'center',
            gap: 'var(--size-4)',
          }}
        >
          <div class={styles.avatars} ref={setAvatarsRef}>
            <For each={djAvatars()}>
              {(avatar, index) => (
                <Image
                  sources={avatar}
                  alt=""
                  style={{ 'z-index': djAvatars().length - index() }}
                />
              )}
            </For>
          </div>
          {[djNames(), genre()].filter(Boolean).join(' · ')}
        </div>
        <p>{radioShow().description}</p>
      </div>
    </div>
  );
}
