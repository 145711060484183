import { createSignal, For } from 'solid-js';
import { usePlayer } from '~/components/player/PlayerContext';

export default function SourceSelect() {
  const player = usePlayer();

  const [currentSourceIndex, setCurrentSourceIndex] = createSignal(
    player.currentSourceIndex,
  );

  return (
    <select
      onChange={event => {
        const value = Number.parseInt(event.target.value, 10);
        setCurrentSourceIndex(value);
        window.jhplayer.currentSourceIndex = value;
      }}
    >
      <For each={player.allSources}>
        {(source, index) => (
          <option value={index()} selected={index() === currentSourceIndex()}>
            {source}
          </option>
        )}
      </For>
    </select>
  );
}
