import { gql } from '@apollo/client/core';
import { A, cache, createAsync, type RouteDefinition } from '@solidjs/router';
import { For } from 'solid-js';

import Player from '~/components/player';
import Title from '~/components/Title';
import TopTags from '~/components/TopTags';
import { fragmentToJsx } from '~/utils/fragmentToJsx';
import { client } from '~/utils/graphql';
import { renderFragment } from '~/utils/renderFragment';
import { toYearMonthPair } from '~/utils/toYearMonthPair';

const MAIN_PAGE = gql`
  query MainPage {
    posts(
      where: { status: PUBLISHED, type: ARTICLE }
      options: { limit: 30, sort: [{ sticky: DESC }, { publishedAt: DESC }] }
    ) {
      id
      slug
      revisionsConnection(where: { edge: { status: CURRENT } }) {
        edges {
          node {
            title
            publishedAt
            category {
              id
              title
              slug
            }
          }
        }
      }
    }
  }
`;

const getMainPage = cache(async () => {
  'use server';

  const { data } = await client.query({ query: MAIN_PAGE });

  return {
    posts: data.posts.map((post: any) => {
      const revision = post.revisionsConnection.edges[0].node;

      return {
        slug: post.slug,
        title: renderFragment(JSON.parse(revision.title), true),
        publishedAt: new Date(revision.publishedAt),
        category: revision.category,
      };
    }),
  };
}, 'homepage');

export const route = {
  preload: () => getMainPage(),
} satisfies RouteDefinition;

export default function Home() {
  const data = createAsync(() => getMainPage(), {
    initialValue: { posts: [] },
  });

  return (
    <main>
      <Title />
      <TopTags />
      <Player />
      <ul>
        <For each={data().posts}>
          {post => (
            <li>
              <A
                href={`/${post.category.slug as string}/${toYearMonthPair(post.publishedAt)}/${post.slug as string}`}
              >
                {fragmentToJsx(post.title)}
              </A>
              <br />
              {post.publishedAt.toLocaleString(import.meta.env.VITE_LOCALE, {
                timeZone: import.meta.env.VITE_TZ,
              })}
              <br />
              <A href={`/${post.category.slug as string}`}>
                {post.category.title}
              </A>
            </li>
          )}
        </For>
      </ul>
    </main>
  );
}
